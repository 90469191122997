export const MONTHLY_PRICE = 6.99;

// prod api
export const API_BASE_URL = "https://menulink.lt/api";
// localhost api
//export const API_BASE_URL = "";

//language
export const DEFAULT_LANGUAGE = "lt"; // en, lt

// localhost
/*export const BASE_URL = "http://localhost:88/nextmenu/public_html";
export const MENU_BASE_URL = "http://localhost:3000/m";
export const ORDER_BASE_URL = "http://localhost:3000/o";
export let OVERWRITE_HOME_PAGE = "";*/

// main prod
/*export const MENU_BASE_URL = "https://menu.menulink.lt/m";
export const ORDER_BASE_URL = "https://menu.menulink.lt/o";
export let OVERWRITE_HOME_PAGE = "";
export const BASE_URL = "https://menulink.lt";*/

// vanduo marse
/*export const MENU_BASE_URL = "https://vanduomarse.menulink.lt/m";
export const ORDER_BASE_URL = "https://vanduomarse.menulink.lt/o";
export let OVERWRITE_HOME_PAGE =
  "https://vanduomarse.menulink.lt/m/61edb4f04ceb6";
export const BASE_URL = "https://menulink.lt";*/

// menulink.eu
/*export const MENU_BASE_URL = "https://menu.menulink.eu/m";
export const ORDER_BASE_URL = "https://menu.menulink.eu/o";
export let OVERWRITE_HOME_PAGE = "";
export const BASE_URL = "https://menulink.lt";*/

// gaisrine
export const BASE_URL = "https://menulink.lt";
export let OVERWRITE_HOME_PAGE = "https://meniu.gaisrine.lt/m/61fa9c9ecac6a";
export const MENU_BASE_URL = "https://meniu.gaisrine.lt/m";
export const ORDER_BASE_URL = "https://meniu.gaisrine.lt/o";

// palangos svyturys
/*export const BASE_URL = "https://menulink.lt";
export let OVERWRITE_HOME_PAGE = "https://meniu.palangossvyturys.lt/m/62d83e9783f00";
export const MENU_BASE_URL = "https://meniu.palangossvyturys.lt/m";
export const ORDER_BASE_URL = "https://meniu.palangossvyturys.lt/o";*/

// pupele
/*export const BASE_URL = "https://menulink.lt";
export let OVERWRITE_HOME_PAGE = "https://pupele.menulink.lt/m/6441362114903";
export const MENU_BASE_URL = "https://pupele.menulink.lt/m";
export const ORDER_BASE_URL = "https://pupele.menulink.lt/o";*/
